<template>
    <router-view v-slot="{ Component }">
        <!-- <keep-alive> -->
        <component :is="Component" />
        <!-- </keep-alive> -->
    </router-view>
</template>

<script>
    // <!-- API -->
    import { defineComponent, provide } from 'vue';

    // <!-- UTILITIES -->
    import { useNoteIndex } from '@/hooks/cache/useNoteIndex';
    import { Resource } from '@/utils/enums';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'NoteIndex',
        setup() {
            // <!-- DEFINE -->
            /** Load the note index systems. */
            const index = useNoteIndex();

            // <!-- PROVIDE -->
            /** Provide the notes data structure to deep children. */
            provide(Resource.notes, index);

            // <!-- METHODS -->
            // Invoke the loading request as soon as the window is selected.
            const onCreated = async () => {
                const notes = await index.refreshNoteIndex();
                console.log(`[refresh::notes] note-manager/index.vue`, notes);
            };

            // <!-- LIFECYCLE -->
            /** onCreated */
            onCreated();

            // <!-- EXPOSE -->
            return {};
        },
    });
</script>
