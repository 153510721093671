<template>
    <BasicModal
        :show="isAddNoteModalOpen"
        @close="onCancel.addNote"
    >
        <AddNoteForm
            :sourceNote="noteToAdd"
            :isEditing="true"
            :isLoading="isLoading"
            @cancel="onCancel.addNote"
            @submit="onSubmit.addNote"
            :debug="isDebug"
        />
    </BasicModal>
    <BasicModal
        :show="isViewNoteModalOpen || isEditNoteModalOpen"
        @close="onCancel.editNote"
    >
        <EditNoteForm
            :sourceNote="noteToEdit"
            :isEditing="isEditing"
            :isLoading="isLoading"
            @switch="onChange.editing"
            @cancel="onCancel.editNote"
            @submit="onSubmit.editNote"
            :debug="isDebug"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete Note"
        confirmationText="Are you sure you want to delete this note? This action cannot be undone."
        :open="isConfirmDeleteModalOpen"
        @cancel="onCancel.deleteNote"
        @delete="onSubmit.deleteNote"
    />
    <NoteManagerContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        View, add, edit, and remove notes.
                    </PageDescription>
                    <ModalButton
                        v-if="isDataAnalyst === false"
                        variant="primary"
                        label="Add Note"
                        :busy="isLoading"
                        @click="onClick.addNote"
                    />
                </PageIntro>
                <LoadingWrapper :isLoading="isLoading">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="pagination.enabled"
                        :paginationPageSize="pagination.size"
                        :columnDefs="isDataAnalyst ? colDefsDA : colDefs"
                        :rowHeight="null"
                        :rowData="rowData"
                        :defaultColDef="defaultColDef"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                        overlayNoRowsTemplate="No notes to display."
                    />
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </NoteManagerContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="alerts.state.alerts.value"
            @dismiss:alert="alerts.methods.dismissAlert($event)"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, onMounted } from 'vue';
    import { useStore } from 'vuex';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import PageWrapper from '@/components/PageWrapper.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import NoteManagerContainer from '~NoteManager/components/wrappers/NoteManagerContainer.vue';
    import AddNoteForm from '~NoteManager/components/forms/AddNoteForm.vue';
    import EditNoteForm from '~NoteManager/components/forms/EditNoteForm.vue';
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useNoteManager } from '~NoteManager/hooks/useNoteManager';

    // <!-- TYPES -->

    /** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
    /** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */
    /** @typedef {import('@/models/notes/Note').NoteResource} NoteResource */

    /** @typedef {ReturnType<import('@/hooks/cache/useNoteIndex').useNoteIndex>} INoteCache */

    /** @typedef {Required<Pick<NoteResource, 'id'>> & Partial<NoteResource>} INoteTarget */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'NoteManager',
        components: {
            ModalButton,
            BaseAlert,
            LoadingWrapper,
            PageIntro,
            PageDescription,
            PageWrapper,
            BasicModal,
            ConfirmDeleteModal,
            NoteManagerContainer,
            AddNoteForm,
            EditNoteForm,
            AgGridVue,
        },
        setup(props, context) {
            // ==== COMPOSE ====
            const store = useStore();

            // ==== COMPOSABLES ====
            const manager = useNoteManager();
            const { alerts, grid, data, actions } = manager;
            const { defaultColDef } = manager.constants;
            const {
                noteIndex,
                noteToAdd,
                noteToEdit,
                rowData,
                colDefs,
                colDefsDA,
            } = data;

            // ==== STATE ====
            /** Debug environment flag. */
            const isDebug = process.env.NODE_ENV !== 'production';
            /** Note page description. */
            const page = { description: 'Note page.' };
            /** @type {{ enabled: Boolean, size: Number }} */
            const pagination = { enabled: true, size: 25 };

            // ==== COMPUTED PROPERTIES ====
            const {
                isLoading,
                isEditing,
                isAddNoteModalOpen,
                isViewNoteModalOpen,
                isEditNoteModalOpen,
                isConfirmDeleteModalOpen,
            } = data;

            const user = computed(() => {
                return store.state.users.me;
            });

            /** @type {V.ComputedRef<Boolean>} */
            const isDataAnalyst = computed(
                () => user.value?.role === 'data-analyst'
            );

            // ==== EVENTS ====
            const { onGridReady, onColumnResized } = grid;
            const { onClick, onChange, onCancel, onSubmit } = actions;
            const { refreshNotes } = actions;

            // ==== LIFECYCLE ====
            onMounted(async () => {
                // Refresh the notes on initial page load.
                await refreshNotes(false);
            });

            // // ==== EXPOSE ====
            return {
                page,
                pagination,
                manager,
                alerts,
                // STATE
                noteIndex,
                noteToAdd,
                noteToEdit,
                defaultColDef,
                rowData,
                colDefs,
                colDefsDA,
                // CONDITIONALS,
                isDebug,
                isLoading,
                isEditing,
                isAddNoteModalOpen,
                isViewNoteModalOpen,
                isEditNoteModalOpen,
                isConfirmDeleteModalOpen,
                // EVENTS
                onClick,
                onChange,
                onCancel,
                onSubmit,
                onGridReady,
                onColumnResized,

                user,
                isDataAnalyst,
            };
        },
    });
</script>
